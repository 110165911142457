import React from "react"
import { Controller, useFormContext } from "react-hook-form"
// import PhoneNumberInput from "react-phone-input-2"
import loadable from "@loadable/component"
import "react-phone-input-2/lib/plain.css"
import useIP from "@hooks/useIP"
import InputBox from "./InputBox"

const PhoneNumberInput = loadable(() => import("react-phone-input-2"))

const PhoneInput = ({ name, label }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const { countryCode } = useIP()

  return (
    <InputBox name={name} label={label} errors={errors}>
      <Controller
        control={control}
        name={name}
        render={({ field: { ref, ...field } }) => (
          <PhoneNumberInput
            {...field}
            inputProps={{
              ref,
            }}
            country={countryCode}
            enableSearch
            inputClass="input-field input-phone"
            dropdownClass="input-phone-dropdown"
            buttonClass="input-field"
            
          />
        )}
      />
    </InputBox>
  )
}

export default PhoneInput
